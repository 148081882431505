<script>
	import Vue from 'vue';
	import VueResource from 'vue-resource';

	Vue.use(VueResource);

	export default {
		name: 'login-container',

		data: function() {
			return {
				init: false,
				loggedIn: false,
				userData: false,
				canLogin: true
			}
		},

		methods: {
			handleLogin: function (userData) {
				this.userData = userData;
				this.loggedIn = this.userData ? true : false;
				this.init = true;
			},
			setCanLogin: function (value) {
				this.canLogin = value;
			}
		},

		computed: {
			showLoggedIn: function () {
				return this.init && this.loggedIn;
			},
			showNotLoggedIn: function () {
				return this.init && ! this.loggedIn;
			}
		},

		created: function () {
			this.$bus.$on('user-login', this.handleLogin)
			this.$bus.$on('can-login', this.setCanLogin);
		}
	}
</script>

<template>
	<div>
		<slot name="logged-in" v-if="showLoggedIn" :user="userData">
		</slot>
		<slot name="not-logged-in" v-else-if="showNotLoggedIn" :canLogin="canLogin">
		</slot>
		<slot name="limbo" v-else>
		</slot>
	</div>
</template>