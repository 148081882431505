window.$ = require('jquery');
window.jQuery = window.$;

$(function(){
      $('body').on('click', '.js-show-login-modal',function(event) {
            $('.modal-shadow').addClass('modal-shadow--visible');
            $('.login-modal').addClass('login-modal--visible');
            $('body').addClass('login-modal-visible');
      });

      $('.js-close-login-modal').on('click', function(event){
            $('.modal-shadow').removeClass('modal-shadow--visible');
            $('.login-modal').removeClass('login-modal--visible');
            $('body').removeClass('login-modal-visible');
      });
});