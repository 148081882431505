window.$ = require('jquery');
window.jQuery = window.$;

$(function(){
      $('.js-show-all-filters').on('click', function(){
            $(this).toggleClass('filterbox__show-more--visible');
            $(this).parent().find('.filterbox__more').toggleClass('filterbox__more--visible');
	  });

	  $('.js-display-mobile-filters').on('click', function(){
		$('.filterbox-holder--mobile').toggleClass('filterbox-holder--visible');
	  });
});