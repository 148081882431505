<script>
import Vue from 'vue'
import VueResource from 'vue-resource'

Vue.use(VueResource);

	export default {
		name: 'work-product-box',

		props: ['workId', 'userData', 'disableOrder'],

		data: function() {
			return {
				products: [],
				minPrice: null,
				response: false,
				errors: false,
				errorResponse: false
			}
		},

		mounted: function() {
			if ( ! this.isDisabled() )
			{
				this.loadProducts()
			}
			else
			{
				this.response = true;
			}
		},

		created: function () {

		},

		methods: {
			loadProducts: function () {
				let data = {
					credentials: true,
					headers: {
						'x-publisher': '' + window.publisher
					}
				};

				this.$http.get(window.pressadminBaseUrl + '/product/' + this.workId + '/', data).then(function (response) {
					this.response = response.body;
					this.products = response.body.data.products;
					this.minPrice = response.body.data.min_price;
					this.errors = false;
				}, function (response) {
					this.errorResponse = response.body;
					this.errors = response.body.error;
				});
			},
			isDisabled: function () {
				return parseInt(this.disableOrder);
			},
			canUserOrder: function () {
				return this.userData && this.userData.can_order_works;
			},
			isVisible: function () {
				return this.response || this.errorResponse;
			}
		},

		computed: {
			hasErrors: function () {
				return this.errors !== false || this.errorType;
			},
			errorType: function () {
				if ( this.isDisabled() )
				{
					return 'cant_order';
				}

				if ( ! this.canUserOrder() )
				{
					return 'user_cant_order';
				}

				if ( this.errorResponse )
				{
					if ( 'error' in this.errorResponse )
					{
						let errors = this.errorResponse.error
						for (let idx in errors)
						{
							let msg = errors[idx];
							if ( msg.indexOf('Du har redan beställt') !== -1 )
							{
								return 'already_ordered';
							}
							else if ( msg.indexOf('Det valda verket') !== '-1' )
							{
								return 'no_recex';
							}
						}
					}
				}

				return false;
			},
			showSelect: function () {
				return ! this.errorType || this.errorType == 'user_cant_order';
			},

			readingCopyBooks: function () {
				let readingCopies = []

				this.products.forEach((product) => {
					if (product.reading_copy_available === true && product.available === true) {
						readingCopies.push(product)
					}
				})

				return readingCopies
			},
		},
	}
</script>

<template>
	<div>
		<slot :products="products" :reading-copy-books="readingCopyBooks" :min-price="minPrice" :visible="isVisible()" :errors="errors" :errorType="errorType" :showSelect="showSelect" :userCanOrder="canUserOrder()">
		</slot>
	</div>
</template>