// Use Browserify to load jQuery etc!
// Ask in #dev if you don't know how.
window.$ = require('jquery');
window.jQuery = window.$;

import browserUpdate from 'browser-update';
browserUpdate({required: {e: -3, f: -3, o: -3, s: -3, c: -3}, insecure: true, api: 2018.10});

import './collapsible';
import './filters';
import './login-modal';
import './lightgallery';
import vueApp from './vue-app';
import common from './common';
import jobylonIframeHeight from './jobylon-iframe-height';
import analytics from './analytics';

analytics();
common();
vueApp();
jobylonIframeHeight();