<script>
	export default {
		name: "newsletter-signup",

		props: [
			'list',
			'interest',
			'ajaxUrl',
			'integrity-policy-url',
		],

		data: function() {
			return {
				placeholder: 'Skriv in din E-postadress här.',
				email: '',
				errorMessage: '',
				successMessage: '',
				disabled: false,
			};
		},

		methods: {
			submit: function() {
				if ( this.disabled )
				{
					return;
				}

				console.debug('Submit newsletter signup');

				this.errorMessage = '';
				this.disabled = true;

				let data = new FormData();
				data.append('action', 'bonnier_newsletter_signup');
				data.append('email', this.email);
				data.append('list', this.list);
				data.append('interest', this.interest);

				this.$http.post(this.ajaxUrl, data).then((response) => {
					if ( response.body.status == 'success' )
					{
						this.successMessage = 'Prenumeration uppdaterad!';
						this.email = '';

						setTimeout(() => {
							this.successMessage = '';
						}, 5000);

						this.disabled = false;

						if ( typeof ga === 'function' )
						{
							ga.getAll()[0].send('event', {
								eventCategory: 'NewsLetters',
								eventAction: 'Click',
								eventLabel: 'SignUp - ' + this.interest
							});
						}
					}
					else
					{
						this.errorMessage = response.body.message;
						this.disabled = false;
					}
				}, (response) => {
					this.disabled = false;
				});
			}
		}
	}
</script>

<template>
	<div>
		<label>E-post</label>
		<input v-model="email" type="text" :placeholder="placeholder">
		<a class="newsletter__link" href="" @click.prevent="submit()">Få nyhetsbrev</a>
		<a class="newsletter__integrity_policy_link" target="_blank" :href="integrityPolicyUrl">Integritetspolicy</a>
		<div class="newsletter__error-message" v-if="errorMessage != ''">{{ errorMessage }}</div>
		<div class="newsletter__success-message" v-if="successMessage != ''">{{ successMessage }}</div>
	</div>
</template>