import $ from 'jquery';

module.exports = function() {
	// Handle jobylon.com iframe height
	window.addEventListener("message", function(event) {
		if ( event.origin !== 'https://cdn.jobylon.com' || !event.data )
		{
			return;
		}

		let data = JSON.parse(event.data);

		if ( data && data.name == 'jbl:setHeight' )
		{
			var frames = document.getElementsByTagName('iframe');
			for ( var i = 0; i < frames.length; i++ )
			{
				if ( frames[i].contentWindow === event.source )
				{
					$(frames[i]).height(data.data); //the height sent from iframe
					break;
				}
			}
		}

	}, false);
};