<script>
	import FilterBox from './filter-box.vue'
	import Common from './common';

	export default {
		name: 'search-contacts',

		components: {
			FilterBox
		},

		mixins: [
			Common
		],

		props: [
			'contact-type',
			'main-contact-search-url',
			'sort-current-days-future',
			'use-path',
			'freetext-min-items'
		],

		data: function() {
			return {
				activeAuthorLinks: null,
				sortOptions: {
					relevance: 'Relevans',
					current: 'Aktuella',
					lastname: 'Efternamn',
					name: 'Förnamn',
				},
				sortBy: 'current',
				contactTypeLabels: {
					author: 'författare',
					illustrator: 'illustratör',
					photographer: 'fotograf',
					reader: 'uppläsare',
				},
				contactTypeLabelsPlural: {
					author: 'författare',
					illustrator: 'illustratörer',
					photographer: 'fotografer',
					reader: 'uppläsare',
				},
			}
		},

		watch: {
			terms: function(newValue, oldValue) {
				if ( oldValue == '' )
				{
					this.sortByOnSearch = 'relevance';
				}

				if ( newValue == '' )
				{
					this.sortByOnSearch = 'lastname';
				}
			}
		},

		methods: {
			getContactTypeLabel: function() {
				if (this.contactTypeLabels[this.contactType])
				{
					return this.contactTypeLabels[this.contactType];
				}

				return null;
			},

			getContactTypeLabelPlural: function() {
				if (this.contactTypeLabelsPlural[this.contactType])
				{
					return this.contactTypeLabelsPlural[this.contactType];
				}

				return null;
			},

			getInitials: function(item) {
				if ( !item.firstName || !item.lastName )
				{
					return '';
				}

				return item.firstName.charAt(0) + item.lastName.charAt(0);
			},

			toggleAuthorLinks: function(id) {
				if ( this.activeAuthorLinks == id )
				{
					this.activeAuthorLinks = null;
				}
				else
				{
					this.activeAuthorLinks = id;
				}
			},

			filtersShouldBeVisible: function() {
				return this.getForcedPublisherFilter() == null && this.displayFilters && Object.keys(this.displayFilters).length > 0;
			},

			getForcedPublisherFilter: function() {
				if ( this.preselectedFiltersParsed.hasOwnProperty('publishers') && this.hiddenFilters.hasOwnProperty('publishers') !== -1 )
				{
					return this.preselectedFiltersParsed.publishers[0];
				}

				return null;
			},

			filterAuthors: function(authors) {
				let _this = this;

				authors.map(function(author) {
					author.contactUrls = _this.filterContactUrls(author.contactUrls);
				});

				return authors;
			},

			filterContactUrls: function(contactUrls) {
				let _this = this;

				contactUrls = contactUrls.filter(function(contactUrl) {
					return _this.getForcedPublisherFilter() == null || _this.getForcedPublisherFilter() == contactUrl.id;
				});

				return contactUrls;
			},

			getContactUrl: function (contact) {
				return contact.contactUrls.length > 0 ? contact.contactUrls[0].url : null;
			},

			search: function(page = null) {
				if ( this.$refs.searchinput )
				{
					this.$refs.searchinput.blur();
				}

				if ( this.sortByOnSearch )
				{
					this.sortBy = this.sortByOnSearch;
					this.sortByOnSearch = null;
				}

				if ( page !== null )
				{
					this.page = page;
				}

				let queryParams = {};

				if (this.terms) {
					queryParams['terms'] = this.terms;
				}

				this.updateUrlHash(queryParams);

				let data = this.getDefaultFilters();

				let extraArgs = this.extraArgs ? this.extraArgs : {};

				Object.assign(data, extraArgs, {
					s: this.terms,
					page: this.page,
					limit: 30,
					type: this.contactType,
				});

				if ( this.sortBy && this.sortBy != 'relevance' )
				{
					data['sort'] = this.sortBy;
					data['currentDaysFuture'] = this.sortCurrentDaysFuture;
				}

				// Add active filters to data
				this.updateAllFilters() // We need to make sure that the processed filters are up to date
				data = Object.assign(data, this.allFiltersWithTerms);

				console.debug('Fetching search results');
				let startTime = new Date();

				let headers = {};

				this.loading = true;

				this.$http.get(this.apiUrl, {
					params: data,
					headers: headers
				}).then(response => {
					let endTime = new Date();

					this.loading = false;

					if ( response.body.success )
					{
						if ( response.body.data ) {
							this.hits = response.body.data.total ? response.body.data.total : 0;
							this.totalPages = response.body.data.total_pages ? response.body.data.total_pages : 0;
							this.items = this.filterAuthors(response.body.data.items ? response.body.data.items : []);
							this.setFilters(response.body.data.filters);
						} else {
							this.hits = 0;
							this.totalPages = 0;
							this.items = [];
							this.displayFilters = [];
						}

						if ( typeof dataLayer === 'object' && this.terms != '' )
						{
							dataLayer.push({
								'event': 'search',
								'term': this.terms
							});
						}
					}

					if ( this.itemsLoaded )
					{
						setTimeout(() => {
							this.scrollToTop();
						}, 50);
					}

					this.itemsLoaded = true;

					let timeDiff = endTime - startTime;

					console.debug('Time spent: ' + timeDiff);
				}, response => {
					console.error('Error.');
				});
			},
		}
	}
</script>

<template>
	<div class="wrapper" v-cloak id="search-top">


		<div v-if="filtersShouldBeVisible()" class="filterbox-holder col-3">
			<filter-box v-for="(filter, filterIndex) in orderedFilters" :filter="filter" :filter-index="filterIndex" :freetext-min-items="freetextMinItems"
					v-on:filter-changed="search(1)" class="filterbox"></filter-box>
		</div>

		<div :class="filtersShouldBeVisible() ? 'col-9 m-col-12' : 'col-12'">
			<h1 class="page-title">{{ title }}</h1>

			<div class="search col-12" :class="loading ? 'search--loading' : ''">
				<div class="col-6">
					<label class="search__input-label">
						<input class="search__input" type="search" name="term" ref="searchinput" :placeholder="'Sök bland våra ' + getContactTypeLabelPlural()" v-model="terms" v-on:keyup.enter="search(1)">
						<button class="search__submit" v-on:click="search(1)"></button>
					</label>
				</div>
				<div class="sort-results col-6">
					<div class="filterbox-holder filterbox-holder--mobile col-12">
						<filter-box v-for="(filter, filterIndex) in displayFilters" :filter="filter" :filter-index="filterIndex" v-on:filter-changed="search(1)" class="filterbox"></filter-box>
					</div>
					<div class="sort-results__views">
						<a @click.prevent="setListingStyle('gallery')" class="sort-results__views__view sort-results__views__view--normal"
						   :class="listingStyle == 'gallery' ? 'sort-results__views__view--active' : ''"></a>
						<a @click.prevent="setListingStyle('list')" class="sort-results__views__view sort-results__views__view--listing"
						   :class="listingStyle == 'list' ? 'sort-results__views__view--active' : ''"></a>
					</div>
					<div class="sort-results__sorting">
						<label>
							Sorterat på:
							<select v-model="sortBy" v-on:change="setPage(1)">
								<option v-for="(label, value) in sortOptions" :value="value">{{ label }}</option>
							</select>
						</label>
					</div>
				</div>
			</div>

			<div class="col-12" v-if="visiableFiltersWithObjects">
				<ul class="search-active-filters">
					<template v-for="activeFilterTerms, activeFilter in visiableFiltersWithObjects">
						<li v-for="activeFilterTerm in activeFilterTerms">
							{{ activeFilterTerm.key }}
							<a href="" v-on:click.prevent="removeFilter(activeFilterTerm)"><img src="/wp-content/themes/bonnierforlagen/img/icon-close.svg" alt="Ta bort filter"></a>
						</li>
					</template>
				</ul>
			</div>

			<div class="col-12" v-if="hits !== null">
				<span class="sort-results__hits">{{ hits }} st träffar</span>

				<span class="sort-results__search-all-publishers" v-if="showAllPublishersLink()">
					Vill du söka bland alla <a
					:href="mainContactSearchUrl + '#' + getCurrentUrlHashWithoutPublishers()">Bonnierförlagens {{ getContactTypeLabelPlural() }}</a>?
				</span>
			</div>

			<div class="search-results-wrapper" :class="loading ? 'search-results-wrapper--loading' : ''">

				<div v-if="listingStyle == 'gallery'" class="search-results search-results--books col-12">
					<div v-for="item in items" class="search-results__item col-2">

						<div v-if="item.contactUrls.length > 1">
							<a class="search-results__link" href="" v-on:click.prevent="toggleAuthorLinks(item.id)">
								<div v-if="item.coverImage" class="search-results__book-cover-wrap">
									<img class="search-results__cover" :src="item.coverImage" :alt="item.title">
								</div>
								<div v-else class="search-results__book-cover-wrap">
									<span class="search-results__initials">{{ getInitials(item) }}</span>
								</div>
								<h3 class="search-results__title">{{ item.fullName }}</h3>
							</a>
							<div class="search-results__author_links_popup" v-bind:class="{ active: activeAuthorLinks == item.id }">
								<ul>
									<li v-for="contactUrl in item.contactUrls">
										<a class="search-results__author_link" :href="contactUrl.url">{{ contactUrl.publisher_name }}</a>
									</li>
								</ul>
							</div>
						</div>

						<a v-else class="search-results__link" :href="getContactUrl(item)">
							<div v-if="item.coverImage" class="search-results__book-cover-wrap">
								<img class="search-results__cover" :src="item.coverImage" :alt="item.title">
							</div>
							<div v-else class="search-results__book-cover-wrap">
								<span class="search-results__initials">{{ getInitials(item) }}</span>
							</div>
							<h3 class="search-results__title">{{ item.fullName }}</h3>
						</a>

					</div>
				</div>


				<div v-else-if="listingStyle == 'list'" class="table-wrapper">
					<div class="table">
						<div class="table__head">
							<div class="table__head__column table__head__column--no-alignment">
								<p>Namn</p>
							</div>
						</div>
						<div class="table__body">
							<div v-for="item in items" class="table__body__row">
								<div class="table__body__row__column table__body__row__column--no-alignment">

									<div v-if="item.contactUrls.length > 1">
										<a href="" v-on:click.prevent="toggleAuthorLinks(item.id)">
											{{ item.fullName }}
										</a>
										<div class="search-results__author_links_popup" v-bind:class="{ active: activeAuthorLinks == item.id }">
											<ul>
												<li v-for="contactUrl in item.contactUrls">
													<a class="search-results__author_link" :href="contactUrl.url">{{ contactUrl.publisher_name }}</a>
												</li>
											</ul>
										</div>
									</div>

									<a v-else class="search-results__link" :href="getContactUrl(item)">
										{{ item.fullName }}
									</a>

								</div>
							</div>
						</div>
					</div>
				</div>

			</div>


			<div class="pagination col-12" v-if="totalPages > 0">
				<div class="pagination__wrap">
					<a class="pagination__first" v-bind:class="{ inactive: page <= 1 }" href="" v-on:click.prevent="setPage(1)"></a>
					<a class="pagination__prev" v-bind:class="{ inactive: page <= 1 }" href="" v-on:click.prevent="setPage(page-1)"></a>

					<span class="pagination__count">Sida {{ page }} av {{ totalPages }}</span>

					<a class="pagination__next" v-bind:class="{ inactive: page + 1 > totalPages }" href="" v-on:click.prevent="setPage(page + 1)"></a>
					<a class="pagination__last" v-bind:class="{ inactive: page + 1 > totalPages }" href=""
					   v-on:click.prevent="setPage(totalPages)"></a>
				</div>
			</div>

		</div>
	</div>
</template>