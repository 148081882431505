<script>
	export default {
		name: "newsletter-signup-headless",

		props: [
			'list',
			'interest',
			'ajaxUrl',
		],

		data: function() {
			return {
				email: ''
			};
		},

		methods: {
			submit: function() {
				if ( this.disabled )
				{
					return;
				}

				console.debug('Submit newsletter signup');

				let data = new FormData();
				data.append('action', 'bonnier_newsletter_signup');
				data.append('email', this.email);
				data.append('list', this.list);
				data.append('interest', this.interest);

				this.$http.post(this.ajaxUrl, data).then((response) => {
					if ( response.body.status == 'success' )
					{
						console.debug('Sucessful');
					}
					else
					{
						console.debug('Error');
					}
				}, (response) => {
					console.debug('Error');
				});
			}
		},

		created: function() {
			this.$bus.$on('pressadmin-user-registration', (email) => {
				if ( email )
				{
					this.email = email;
					this.submit();
				}
			});
		}
	}
</script>

<template>

</template>