<template>
  <div class="publisher-bar">

      <div class="publisher-bar__content publisher-bar__bg">
		  <div class="wrapper">
			<p v-on:click="visible = !visible">
				{{ title }}
				<svg v-if="!this.visible" width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path fill-rule="evenodd" clip-rule="evenodd" d="M-2.78088e-07 6.12125L6.30687 12.4832L12.6142 6.12125L10.9678 4.46048L7.51687 7.94156L7.51687 0.877894L5.18855 0.877894L5.18855 8.03354L1.64636 4.46048L-2.78088e-07 6.12125Z" fill="white"/>
				</svg>
				<svg v-if="this.visible" width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path fill-rule="evenodd" clip-rule="evenodd" d="M12.66 7.20138L6.35317 0.839477L0.0458343 7.20138L1.69224 8.86215L5.14316 5.38107L5.14316 12.4447L7.47148 12.4447L7.47148 5.28909L11.0137 8.86215L12.66 7.20138Z" fill="white"/>
				</svg>
			</p>
		  </div>
      </div>
	  <div v-if="this.visible" class="publisher-bar__bg__transparent">
		  <div class="wrapper">
			  <div class="publisher-bar__publishers">
				  <div v-for="(publisher, index) in publishers" :key="index">
					<a :href="publisher.link">
						<img :src="publisher.logo" :alt="publisher.name">
						<p style="color:white;">{{publisher.name}}</p>
					</a>
				  </div>
			  </div>
		  </div>
	  </div>
    </div>
</template>

<script>
export default {
  name: "publisher-bar",
	props: ['title'],
  data() {
    return {
      publishers: null,
	  visible: false,
    };
  },
  mounted: function () {
    this.getPublishers();
  },
  methods: {
    getPublishers() {
      var data = {
        action: 'get_all_publishers',
      };

      axios
        .post(AJAX_URL, qs.stringify(data))
        .then((response) => {
          this.publishers = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>