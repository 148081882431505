<script>
import {cloneDeep} from 'lodash'

export default {
	name: 'checkout',

	props: [
		'userData',
		'booksUrl',
		'authorsUrl',
	],

	data: function() {
		return {
			state: 'checkout',
			error: null,
			cartItems: [],
			cartItemsOnServer: [],
			spinner: false,
			updatingCart: false,
			fields: {
				customerReference: null,
				customerOrderNumber: null,
			},
		}
		},

		mounted: function() {
			if (this.userData)
			{
				this.updateCartItems();
			}
		},

		created: function() {
			this.$bus.$on('cart:updated', this.updateCartItems);
		},

		watch: {
			userData: function(newUserData) {
				this.updateCartItems();
			}
		},

		methods: {
			updateCartItems: function() {
				this.cartItems = cloneDeep(this.userData.cart.items)
				this.cartItemsOnServer = cloneDeep(this.userData.cart.items)
			},

			removeItem: function(item) {
				let data = {
					isbn: item.isbn,
				};

				this.$http.post(window.pressadminBaseUrl + '/cart/remove/', data, {credentials: true}).then(function(response) {
					if ( response.body.cart )
					{
						this.cartItems = response.body.cart.items;
						this.$bus.$emit('cart:updated', response.body.cart);
						this.$bus.$emit('cart:item-removed', data.isbn);
					}
				}, function(response) {

				});
			},

			emptyCart: function() {
				let data = {};

				this.$http.post(window.pressadminBaseUrl + '/cart/empty/', data, {credentials: true}).then(function(response) {
					if ( response.body.cart )
					{
						this.cartItems = response.body.cart.items;
						this.$bus.$emit('cart:updated', response.body.cart);
						this.$bus.$emit('cart:emptied');
					}
				}, function(response) {

				});
			},

			getCartSummary: function() {
				let summary = {};

				for (let item of this.cartItemsOnServer) {
					if ( !summary.hasOwnProperty(item.isbn) )
					{
						summary[item.isbn] = {count: 0}
					}

					let quantity = item.hasOwnProperty('quantity') ? item.quantity : 1
					quantity = parseInt(quantity)
					quantity = Number.isNaN(quantity) ? 0 : quantity
					quantity = Math.max(quantity, 0)

					summary[item.isbn]['count'] += quantity
					summary[item.isbn]['title'] = item.title
					summary[item.isbn]['image'] = item.image
					summary[item.isbn]['url'] = item.url
					summary[item.isbn]['authors'] = item.authors
					summary[item.isbn]['bindingType'] = item.bindingType
					summary[item.isbn]['price'] = item.price
				}

				return summary;
			},

			getCartTotalSum: function() {
				let sum = 0

				for (let item of this.cartItemsOnServer) {
					let quantity = item.hasOwnProperty('quantity') ? item.quantity : 1
					quantity = parseInt(quantity)
					quantity = Number.isNaN(quantity) ? 0 : quantity
					quantity = Math.max(quantity, 0)

					sum += quantity * item.price
				}

				return sum
			},

			submitOrder: function() {
				this.error = null
				this.spinner = true;

				let data = {}

				if ( this.$root.themeName == 'aterforsaljare' )
				{
					data.customer_reference = this.fields.customerReference
					data.customer_order_number = this.fields.customerOrderNumber
				}

				this.$http.post(window.pressadminBaseUrl + '/cart/create_order/', data, {credentials: true}).then(function(response) {

					if ( response.body.success )
					{
						setTimeout(() => {
							this.spinner = false;
							this.cartItems = response.body.cart.items;
							this.state = 'thanks';
							$(window).scrollTop(0);
							this.$bus.$emit('cart:updated', response.body.cart);
						}, 2000);

						if ( typeof ga === 'function' )
						{
							ga.getAll()[0].send('event', {
								eventCategory: 'PressUsers',
								eventAction: 'Click',
								eventLabel: 'OrderSubmit'
							});
						}
					}
				}, (response) => {
					this.spinner = false;
					this.error = 'Något gick fel, försök igen senare.';

					setTimeout(() => {
						this.error = null;
					}, 10000);
				});
			},
			updateCart: function () {
				this.updatingCart = true;

				let items = this.cartItems.map((item) => {
					return {
						isbn: item.isbn,
						quantity: item.quantity
					}
				});

				this.$http.post(window.pressadminBaseUrl + '/cart/update/', {items: items}, {credentials: true}).then(function(response) {

					if ( response.body.success )
					{
						setTimeout(() => {
							this.updatingCart = false;
							this.$bus.$emit('cart:updated', response.body.cart);
						}, 2000);
					}
				}, function(response) {

				});


			}
		}
	}
</script>

<template>
	<div>
		<div v-if="state == 'checkout'" class="checkout">
			<div class="checkout__items">
				<h1 v-if="$root.themeName == 'aterforsaljare'" class="checkout__title">Varukorg</h1>
				<h1 v-else class="checkout__title">Beställningar</h1>

				<div class="checkout__table-holder">

					<slot name="cart" :cartItems="cartItems" :remove-item="removeItem" :updateCart="updateCart" :updatingCart="updatingCart" :emptyCart="emptyCart">

						<div v-if="!cartItems.length" class="checkout__no-items">
							<p>Du har inte lagt till några recensionsexemplar än. <strong>Ta en titt bland våra <a href="/bocker/">böcker</a></strong></p>
						</div>

						<table  v-if="cartItems.length" class="checkout__table">
							<thead>
							<tr>
								<th class="cover">Omslag</th>
								<th>Titel</th>
								<th>Bandtyp</th>
								<th></th>
							</tr>
							</thead>
							<tbody>
							<tr v-for="item in cartItems">
								<td class="cover">
									<img :src="item.image" :alt="item.title">
								</td>
								<td>
									<a :href="item.url">
										<h2>{{ item.title }}</h2>
									</a>

									<p>
										<span>
											<template v-for="(author, authorIndex) in item.authors">{{ author.name }}<template v-if="authorIndex < item.authors.length-1">, </template></template>
										</span>
									</p>

									<span>ISBN: {{ item.isbn }}</span>
								</td>
								<td>
									<p>
										{{ item.bindingType }}
									</p>
								</td>
								<td>
									<a class="remove-line" href="" @click.prevent="removeItem(item)">Ta bort</a>
								</td>
							</tr>
							</tbody>
						</table>
					</slot>
				</div>
			</div>

			<div class="checkout__sidebar">
				<slot name="totals"
					  :cart-items="cartItems"
					  :get-cart-summary="getCartSummary"
					  :get-cart-total-sum="getCartTotalSum"
					  :spinner="spinner"
					  :submit-order="submitOrder"
					  :fields="fields"
					  :error="error"
				>
					<div v-if="cartItems.length" class="checkout__total">
						<h2 class="checkout__title">Totalt</h2>
						<ul>
							<li v-for="item in getCartSummary()">
								<span>{{ item.count }}</span>
								<p>{{ item.bindingType }}</p>
							</li>
						</ul>
						<div v-if="error != null" class="checkout__error">
							{{ error }}
						</div>

						<button v-if="error == null" class="button button--spinner" :class="spinner ? 'spin' : ''" @click.prevent="submitOrder()">
							<em>Bekräfta order</em>
							<svg version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
								 viewBox="0 0 100 100" enable-background="new 0 0 0 0" xml:space="preserve">
								<path fill="#fff" d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50">
									<animateTransform
										attributeName="transform"
										attributeType="XML"
										type="rotate"
										dur="1s"
										from="0 50 50"
										to="360 50 50"
										repeatCount="indefinite"/>
								</path>
								</svg>
						</button>

					</div>
				</slot>

				<div class="checkout__contact" v-if="userData">
					<slot name="userdata" :user-data="userData">
						<h2 class="checkout__title">Mina uppgifter</h2>
						<p v-if="userData.has_cn" style="margin-bottom: 20px;"><strong>Har du uppdaterat dina kontaktuppgifter? Vänta med att lägga din beställning, då det tar ett dygn för uppdateringen att slå igenom i våra system.</strong></p>

						<div class="checkout__contact__section">

							<ul>
								<li><span>Förnamn</span>
									<p>{{ userData.first_name }}</p></li>
								<li><span>Efternamn</span>
									<p>{{ userData.last_name }}</p></li>
							</ul>
						</div>

						<div class="checkout__contact__section">
							<ul>
								<li><span>Företag</span>
									<p>{{ userData.company }}</p></li>
								<li><span>Gatuadress</span>
									<p>{{ userData.address }}</p></li>
								<li><span>Mottagare</span>
									<p>{{ userData.recipient }}</p></li>
								<li><span>Postnummer</span>
									<p>{{ userData.zipcode }}</p></li>
								<li><span>Ort</span>
									<p>{{ userData.city }}</p></li>
								<li><span>Land</span>
									<p>{{ userData.country }}</p></li>
							</ul>
						</div>

						<div class="checkout__contact__section">
							<ul>
								<li><span>E-postadress</span>
									<p>{{ userData.email }}</p></li>
							</ul>
						</div>

						<div class="checkout__edit">
							<a href="/order/konto/" class="button button--white">Se alla uppgifter/ändra</a>
							<ul>
								<li><a href="/order/historik/">Orderhistorik</a></li>
							</ul>
						</div>
					</slot>
				</div>

			</div>
		</div>

		<div v-if="state == 'thanks'" class="order-completed">
			<slot name="order-completed">
				<div class="order-completed__holder">
					<h1>Tack för din beställning!</h1>
					<p>Orderbekräftelse skickas till din e-postadress.</p>

					<div class="order-completed__button-holder">
						<a class="button button--white" :href="booksUrl">Till böckerna</a>
						<a class="button button--white" :href="authorsUrl">Till författarna</a>
					</div>
				</div>
			</slot>
		</div>
	</div>
</template>