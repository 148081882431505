<script>
	import Fuse from 'fuse.js';

	export default {
		name: 'filter-box',

		props: {
			filter: Object,
			filterIndex: String,
			freetextMinItems: {
				type: [Number, String],
				default: 15
			}
		},

		data: function() {
			return {
				showNum: 3,
				freetext: '',
				freetextFilteredItems: [],
				fuse: null,
			}
		},

		computed: {
			hasActive: function() {
				return this.filter['has_active'];
			},
		},

		methods: {
			resetFilter: function() {
				if (this.hasActive)
				{

					for ( let filterItem in this.filter['items'] )
					{
						this.filter['items'][filterItem]['active'] = false;
					}

					this.$emit('filter-changed');
				}
			},

			activeItems: function() {
				let items = [];

				for ( let filterItem in this.filter['items'] )
				{
					if ( this.filter['items'][filterItem]['active'] )
					{
						items.push(this.filter['items'][filterItem]);
					}
				}

				return items;
			},

			initFuse: function() {
				let options = {
					shouldSort: true,
					threshold: 0.3,
					location: 0,
					distance: 100,
					maxPatternLength: 32,
					minMatchCharLength: 1,
					keys: [
						'key'
					]
				};

				this.fuse = new Fuse(this.filter['items'], options);
			}
		},

		created: function() {
			this.initFuse();

			this.$on('filter-changed', () => {
				this.freetext = '';
			});
		},

		watch: {
			'filter.items': function(filter, oldFilter) {
				this.initFuse();
			},

			freetext: function(value, oldValue) {
				let items = [];

				if ( value.length >= 1 )
				{
					let matches = this.fuse.search(value);

					if ( matches.length > 0 )
					{
						items = items.concat(matches.slice(0, 15));
					}
				}

				this.freetextFilteredItems = items;
			}
		},


	}
</script>

<template>
	<div class="filterbox">
		<a href="" class="filterbox__reset" v-if="hasActive" v-on:click.prevent="resetFilter()"><img src="/wp-content/themes/bonnierforlagen/img/icon-close.svg" alt="Återställ filter"></a>
		<h3 class="filterbox__title">{{ filter['name'] }}</h3>


		<div v-if="filter['items'].length >= freetextMinItems">
			<input class="filterbox__freetext" type="text" v-model="freetext" :placeholder="'Hitta ' + filter['name'].toLowerCase()">


			<div v-if="freetextFilteredItems.length == 0 && freetext.length > 0" class="filterbox__no-match">
				(Inga träffar)
			</div>

			<div class="filterbox__checkboxes">
				<label v-for="(item, itemIndex) in freetextFilteredItems" class="filterbox__row"
					   :for="'f-' + filterIndex + '-' + itemIndex">{{ item['key'] }} <span class="filterbox__count">{{ item['doc_count'] }}</span>
					<input class="filterbox__input" type="checkbox" v-model="item['active']" :id="'f-' + filterIndex + '-' + itemIndex"
						   v-on:change="$emit('filter-changed')"/>
					<span class="filterbox__checkmark"></span>
				</label>
			</div>
		</div>

		<div v-if="freetextFilteredItems.length == 0 && freetext.length == 0">
			<div class="filterbox__checkboxes">
				<label v-for="(item, itemIndex) in filter['items'].slice(0, showNum)" class="filterbox__row"
					   :for="'f-' + filterIndex + '-' + itemIndex">{{ item['key'] }} <span class="filterbox__count">{{ item['doc_count'] }}</span>
					<input class="filterbox__input" type="checkbox" v-model="item['active']" :id="'f-' + filterIndex + '-' + itemIndex"
						   v-on:change="$emit('filter-changed')"/>
					<span class="filterbox__checkmark"></span>
				</label>
			</div>
			<a class="filterbox__show-more js-show-all-filters" href="javascript:;" v-if="filter['items'].length > showNum"
			   v-on:click.prevent="showNum += 10">Visa fler</a>
		</div>
	</div>
</template>