<script>
import Vue from 'vue'
import VueResource from 'vue-resource'

Vue.use(VueResource);

	export default {
		name: 'reading-copy-select',

		props: ['products', 'defaultLabel'],

		data: function() {
			return {
				selectedProduction: '',
				adding: false,
				orderComplete: false,
			}
		},

		mounted: function() {
			let data = {
				credentials: true,
				headers: {
					'x-publisher': '' + window.publisher
				}
			};
		},

		methods: {
			order: function() {
				this.adding = true;

				let data = {
					isbn: this.selectedProduction
				};

				let options = {
					credentials: true,
					headers: {
						'x-publisher': '' + window.publisher
					}
				};

				this.$http.post(window.pressadminBaseUrl + '/reading-copy/order', data, options).then(function(response) {
					if (response.status == 200) {
						this.$bus.$emit('readingcopy:ordered');

						setTimeout(() => {
							this.adding = false;
							this.selectedProduction = '';
							this.orderComplete = true

						}, 1000);
					}
				}, function(response) {
					this.adding = false;
				});

				if ( typeof ga === 'function' )
				{
					ga.getAll()[0].send('event', {
						eventCategory: 'ResellerUsers',
						eventAction: 'Click',
						eventLabel: 'ReadingCopyOrder'
					});
				}
			}
		}
	}
</script>

<template>
	<div v-if="products.length > 0">
		<section class="book__content__example">
			<h2>Beställ läsexemplar</h2>
			<select v-model="selectedProduction" v-if="!orderComplete">
				<option value="">{{ defaultLabel }}</option>
				<option :value="product.id" v-for="product in products">
					{{ product.publicName }}
				</option>
			</select>
			<button class="button button--add-to-cart" :class="{ adding: adding, 'button--green': $root.themeName == 'aterforsaljare' }" type="submit"
					v-bind:disabled="selectedProduction == '' || adding" v-on:click="order">
				<em v-if="orderComplete">Vi skickar din bok inom kort – trevlig läsning!</em>
				<em v-else>Beställ</em>
				<svg x="0px" y="0px" width="32px" height="32px" viewBox="0 0 32 32">
					<path stroke-dasharray="19.79 19.79" fill="none" :stroke="$root.themeName == 'aterforsaljare' ? '#000000' : '#FFFFFF'"
						  stroke-width="2" stroke-linecap="square" stroke-miterlimit="10" d="M9,17l3.9,3.9c0.1,0.1,0.2,0.1,0.3,0L23,11"/>
				</svg>
			</button>
		</section>
	</div>
</template>