<script>
	import Vue from 'vue';
	import VueResource from 'vue-resource';

	Vue.use(VueResource);

	export default {
		name: 'ajax-form',

		data: function() {
			return {
				formData: {
				},
				submitting: false,
				response: null,
				errorResponse: null,
				success: false
			}
		},

		props: [
			'url',
			'defaultValues',
			'method',
			'headers',
			'formType',
			'successTemplate'
		],

		methods: {
			onSubmit: function () {

				if (this.submitting)
					return;

				this.submitting = true
				this.success = false;

				let self = this;

				var successCallback = function(response) {

					setTimeout(() => {
						this.response = response.body
						this.submitting = false
						$(window).scrollTop(0);
						self.success = true;
					}, 2000);


					this.submitSuccess();
					this.$emit('success', this.response);
				}

				var errorCallback = function (response) {

					setTimeout(() => {
						this.errorResponse = response.body
						this.submitting = false
					}, 2000);
				}

				if ( this.ajaxMethod == 'get' )
				{
					console.log('get');
				}
				else
				{
					var headers = this.defaultHeaders
					var options = {
						emulateJSON: true,
						credentials: true,
						headers: headers
					}

					this.$http.post(this.url, this.formData, options).then(successCallback, errorCallback);
				}
			},

			submitSuccess: function() {
				if ( this.formType == 'pressadmin-register-account' )
				{
					console.debug('User registration successful, trigger newsletter signup');
					this.$bus.$emit('pressadmin-user-registration', this.formData.email);

					if ( typeof ga === 'function' )
					{
						ga.getAll()[0].send('event', {
							eventCategory: 'PressUsers',
							eventAction: 'Click',
							eventLabel: 'PressUserSignup'
						});
					}

					if (typeof dataLayer === 'object')
					{
						var data = {
							'event': 'signup',
						};
						dataLayer.push(data);
					}
				} else if (this.formType == 'pressadmin-login') {
					if (typeof dataLayer === 'object')
					{
						var data = {
							'event': 'login',
						};
						dataLayer.push(data);
					}

					// It's a bit confusing and should probably be refactored, but the same form is used for both
					// the press and reseller login, so we need to check the themeName
                    if (this.$root.themeName != 'aterforsaljare') {
						// If current domain does not match the domain in this.ssoBaseUrl, start the SSO process,
						// in reverse order, setting up the session on the base URL and then redirecting back to the
						// current page.
						if (window.location.hostname !== window.ssoBaseUrl.replace(/(^\w+:|^)\/\//, '')) {
							const url = new URL(window.ssoBaseUrl + '/pressadmin-api/v1/auth-start/');
							url.searchParams.append('reverse', 'true');
							url.searchParams.append('source_url', window.location.href);

							window.location.href = url.toString();
							return
						}
					}

					window.location.reload();
				}
			}
		},

		computed: {
			ajaxMethod: function () {
				return this.method ? this.method.toLowerCase() : 'post'
			},

			defaultHeaders: function () {
				return this.headers ? this.headers : {}
			},
			formVisible: function () {
				return ! this.success || ! this.successTemplate;
			}
		},

		created: function () {
			Object.assign(this.formData, this.formData, this.defaultValues);
		}
	}
</script>

<template>
	<form @submit.prevent="onSubmit">
		<slot :formData="formData" :submitting="submitting" :response="response" :errorResponse="errorResponse" :submit="onSubmit" v-if="formVisible">
		</slot>
		<slot v-else name="success" :formData="formData" :response="response"></slot>
	</form>
</template>