<script>
	import ClickOutside from 'vue-click-outside';

	export default {
		name: 'account-button',

		data: function() {
			return {
				showMenu: false,
			}
		},

		props: [
			'logout-url',
			'main-site-url',
			'user-data',
		],

		methods: {
			toggleShowMenu: function()
			{
				this.showMenu = ! this.showMenu;
			},
			hideMenu: function()
			{
				this.showMenu = false;
			},
			doLogout: function() {
				var data = {
					credentials: true,
					headers: {
						'x-publisher': '' + window.publisher
					}
				};

				this.$http.get(window.pressadminBaseUrl + '/user/logout/', data).then(function(response) {

					if (typeof dataLayer === 'object')
					{
						var data = {
							'event': 'logout',
						};
						dataLayer.push(data);
					}

					setTimeout(() => {
						this.reloadAfterLogout(true);
					}, 1000);
				}, function(response) {
					this.reloadAfterLogout(false);
				});
			},
			reloadAfterLogout: function (success) {
				if (this.logoutUrl && success)
				{
					window.location.href = this.logoutUrl;
				}
				else
				{
					window.location.reload();
				}
			}
		},

		directives: {
			ClickOutside,
		}
	}
</script>

<template>
	<div v-click-outside="hideMenu">
		<a href="" class="site-menu__account" @click.prevent="toggleShowMenu">
			<div class="site-menu__account__icon"></div>
			<span class="login-text">Konto</span>
		</a>

		<div class="miniaccount" :class="showMenu ? 'miniaccount--active' : ''">
			<h2 v-if="$root.themeName == 'aterforsaljare'">Konto</h2>
			<h2 v-else>Mediakonto</h2>

			<div class="miniaccount__info" v-if="$root.themeName == 'aterforsaljare'">
				<div class="miniaccount__info__icon"></div>
				<div class="miniaccount__info__text">
					<p>Kundnummer: {{ userData.customer_number }}</p>
					<p v-if="userData.delivery_company">{{ userData.delivery_company }}</p>
				</div>
			</div>

			<ul>
				<li><a href="/order/konto/">Mina uppgifter</a></li>
				<li v-if="$root.themeName == 'aterforsaljare'"><a href="/kundreskontra/fakturakopior/">Fakturakopior</a></li>
				<li v-if="$root.themeName == 'aterforsaljare'"><a href="/kundreskontra/kontoutdrag/">Kontoutdrag</a></li>
				<li v-if="$root.themeName != 'aterforsaljare'"><a href="/order/historik/">Orderhistorik</a></li>
				<li v-if="$root.themeName != 'aterforsaljare'"><a :href="mainSiteUrl + 'presstjanst/om-mediatjansten/'">Om Mediatjänsten</a></li>
				<li><a :href="mainSiteUrl + 'integritet/'">Integritetspolicy</a></li>
			</ul>
			<a class="button button--black" href="" @click.prevent="doLogout()">Logga ut</a>
		</div>
	</div>
</template>