<script>
	import Vue from 'vue';
	import VueResource from 'vue-resource';

	Vue.use(VueResource);

	export default {
		name: 'production-select',

		props: ['products', 'canOrder', 'withQuantity', 'defaultLabel'],

		data: function() {
			return {
				selectedProduction: '',
				quantity: 1,
				adding: false,

			}
		},

        computed: {
            selectedProductionItem: function() {
                if ( this.selectedProduction )
                {
                    return this.products.find((item) => {
                        return item.id == this.selectedProduction;
                    });
                }

                return null;
            }
        },

		mounted: function() {
			let data = {
				credentials: true,
				headers: {
					'x-publisher': '' + window.publisher
				}
			};
		},

		methods: {
			addToCart: function() {
				this.adding = true;

				let data = {
					isbn: this.selectedProduction
				};

				if ( this.withQuantity )
				{
					data.quantity = this.quantity;
				}

				let options = {
					credentials: true,
					headers: {
						'x-publisher': '' + window.publisher
					}
				};

				this.$http.post(window.pressadminBaseUrl + '/cart/add/', data, options).then(function(response) {
					if (response.body.cart) {
						this.$bus.$emit('cart:updated', response.body.cart);
						this.$bus.$emit('cart:item-added', data.isbn);

						setTimeout(() => {
							this.adding = false;
							this.selectedProduction = '';
							this.quantity = 1;

						}, 1000);
					}
				}, function(response) {

				});

				if ( typeof ga === 'function' )
				{
					ga.getAll()[0].send('event', {
						eventCategory: 'PressUsers',
						eventAction: 'Click',
						eventLabel: 'AddCart'
					});
				}
			}
		}
	}
</script>

<template>
	<div>
		<select id="production-select" v-model="selectedProduction">
			<option value="">{{ defaultLabel }}</option>
			<option :value="product.id" v-for="product in products" :disabled="product.hasOwnProperty('available') && ! product.available">
				{{ product.publicName }}
				<template v-if="product.price">
					(<template v-if="product.standard_price && product.standard_price != product.price">rea {{ product.price }} kr, ordinarie pris
						{{ product.standard_price }} kr
					</template><template v-else>{{ product.price }} kr</template>)
				</template>
                <template v-if="product.hasOwnProperty('available') && product.available && product.hasOwnProperty('availability_comment') && product.availability_comment != ''">
                    ⚠
                </template>
			</option>
		</select>
		<input type="number" v-model="quantity" v-if="withQuantity" />
		<button v-if="canOrder" class="button button--add-to-cart" :class="{ adding: adding, 'button--green': $root.themeName == 'aterforsaljare' }" type="submit" v-bind:disabled="selectedProduction == '' || adding" v-on:click="addToCart">
			<em>Lägg till</em>
			<svg x="0px" y="0px" width="32px" height="32px" viewBox="0 0 32 32">
				<path stroke-dasharray="19.79 19.79" fill="none" :stroke="$root.themeName == 'aterforsaljare' ? '#000000' : '#FFFFFF'" stroke-width="2" stroke-linecap="square" stroke-miterlimit="10" d="M9,17l3.9,3.9c0.1,0.1,0.2,0.1,0.3,0L23,11"/>
			</svg>
		</button>

        <div style="margin-top: 15px;" v-if="selectedProductionItem && selectedProductionItem.hasOwnProperty('availability_comment') && selectedProductionItem.availability_comment != ''">
            <strong>Notera:</strong> Den valda produkten har status "{{ selectedProductionItem.availability_comment }}" vilket kan komma att påverka leveranstiden.
        </div>
	</div>
</template>