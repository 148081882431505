import Vue from 'vue';
import queryString from 'query-string';
import VueScrollTo from 'vue-scrollto';
import VueResource from 'vue-resource';

Vue.use(VueResource);
Vue.use(VueScrollTo);

module.exports = {
	props: [
		'title',
		'api-url',
		'preselected-filters',
		'hidden-filters',
		'extra-args',
		'filter-order',
		'use-sort-options',
		'use-sort-by'
	],

	data: function() {
		return {
			terms: '',
			items: [],
			itemsLoaded: false,
			loading: false,
			hits: null,
			page: 1,
			totalPages: 0,
			displayFilters: {},
			preselectedFiltersParsed: {},
			sortBy: 'relevance',
			sortByOnSearch: null,
			sortOptions: {
				relevance: 'Relevans',
			},
			listingStyle: 'gallery',
			currentQueryParams: {},
			allFiltersWithObjects: {},
			allFiltersWithTerms: {},
			visiableFiltersWithObjects: {},
			visiableFiltersWithTerms: {},
			searchEnabled: true
		}
	},

	created: function() {
		if ( typeof this.preselectedFilters == 'string' )
		{
			this.preselectedFiltersParsed = JSON.parse(this.preselectedFilters);
		}
		else
		{
			this.preselectedFiltersParsed = this.preselectedFilters;
		}

		this.sortOptions = this.useSortOptions ? this.useSortOptions : this.sortOptions;

		this.setStateFromHash();

		this.sortBy = this.useSortBy ? this.useSortBy : this.sortBy;

		window.addEventListener("hashchange", this.setStateFromHash, false);
	},

	watch: {
		displayFilters: {
			deep: true,
			handler(filters)
			{
				this.updateAllFilters()
			}
		},
		visibleFilters: {
			deep: true,
			handler(filters)
			{
				this.updateVisibleFilters()
			}
		}
	},

	computed: {
		visibleFilters: function() {
			let filters = {}

			for ( let filterKey in this.displayFilters )
			{
				if ( !this.hiddenFilters.hasOwnProperty(filterKey) )
				{
					filters[filterKey] = this.displayFilters[filterKey];
				}
			}

			return filters;
		},

		orderedFilters: function() {
			let filters = this.visibleFilters;

			let selectedFilterOrder = this.getFilterOrder();
			let filterOrder = selectedFilterOrder.slice();

			let result = {}

			for ( let filterKey in filters )
			{
				if ( selectedFilterOrder.indexOf(filterKey) === -1 )
				{
					filterOrder.push(filterKey);
				}
			}

			for ( let idx in filterOrder )
			{
				let filterKey = filterOrder[idx];
				if ( filters[filterKey] )
				{

					result[filterKey] = filters[filterKey];
				}
			}

			return result;
		},
	},

	methods: {
		scrollToTop: function() {
			this.$scrollTo('.sort-results__sorting', 500, {offset: -250});
		},

		isSearchEnabled: function () {
			return this.searchEnabled;
		},

		getFilterOrder: function () {
			return this.filterOrder ? this.filterOrder : [];
		},

		getDefaultFilters: function () {
			let filters = {}

			for ( let filterKey in this.preselectedFiltersParsed )
			{
				if (this.hiddenFilters.hasOwnProperty(filterKey))
				{
					filters[filterKey] = this.preselectedFiltersParsed[filterKey];
				}
			}

			return filters;
		},

		setFilters: function(filters, keepOptionsFor) {


			// We need to initially set the 'showMore' property for watching to work
			for ( let filterKey in filters )
			{
				filters[filterKey]['showMore'] = false;

				for ( let filterItemKey in filters[filterKey]['items'] )
				{
					let filter = filters[filterKey]['items'][filterItemKey];

					// If id is not set, use key
					if ( !filter['id'] )
					{
						filter['id'] = filter['key'];
					}
				}
			}

			if ( keepOptionsFor && keepOptionsFor.length )
			{
				let displayFilters = this.displayFilters;
				keepOptionsFor.forEach(function (k) {
					let filter = displayFilters[k]['items'];
					let newFilters = filters[k]['items'];
					let newFilterIds = newFilters.map(function (item) {
						return item['id'];
					});

					filter = filter.filter(function (item) {
						return newFilterIds.indexOf(item['id']) === -1;
					});

					filters[k]['items'] = newFilters.concat(filter);
				});
			}

			this.displayFilters = filters;

			this.$emit('filters-loaded');
		},

		setStateFromHash: function() {
			let state = queryString.parse(location.hash);

			if ( Object.keys(state).length === 0 && Object.keys(this.preselectedFiltersParsed).length > 0 )
			{
				// No filters selected by the user, use filters pre-selected by admin
				state = this.preselectedFiltersParsed;
			}

			let filters = {};

			for ( let key in state )
			{
				if ( key == 'terms' )
				{
					this.terms = state[key];
				}
				else if ( key == 'page' )
				{
					this.page = parseInt(state[key]);
				}
				else if (key == 'sort')
				{
					this.sortBy = state[key];
				}
				else if ( key == 'style' )
				{
					this.listingStyle = state[key];
				}
				else
				{
					let filterContent = state[key];

					if ( !Array.isArray(filterContent) )
					{
						filterContent = [filterContent];
					}

					filters[key] = {};
					filters[key]['items'] = [];

					filterContent.map(function(filterValue) {
						filters[key]['items'].push({
							id: filterValue,
							active: true,
						});
					});
				}
			}

			this.displayFilters = filters;

			let _this = this;

			// Let things settle before doing the search
			setTimeout(function() {
				console.log('set state from hash search')
				_this.search();
			}, 0);
		},

		processFilters: function(filters) {
			let dataWithTerms = {}
			let dataWithObjects = {}

			for ( let filterKey in filters )
			{
				for ( let filterItemKey in this.displayFilters[filterKey]['items'] )
				{
					let filter = this.displayFilters[filterKey]['items'][filterItemKey];

					if ( filter.active )
					{
						let term = filter['id'];

						if ( term != '' )
						{
							let filterName = this.displayFilters[filterKey]['name'];

							if ( filterName )
							{
								if ( !dataWithObjects[filterName] )
								{
									dataWithObjects[filterName] = [];
								}

								dataWithObjects[filterName].push(filter);
							}

							if ( !dataWithTerms[filterKey] )
							{
								dataWithTerms[filterKey] = [];
							}

							dataWithTerms[filterKey].push(term);
						}
					}
				}
			}

			let singleKeys = ['start_date', 'end_date'];

			for ( let idx in singleKeys )
			{
				let key = singleKeys[idx];

				if ( dataWithTerms[key] )
				{
					dataWithTerms[key] = dataWithTerms[key][0];
				}

				if ( dataWithObjects[key] )
				{
					dataWithObjects[key] = dataWithObjects[key][0];
				}
			}

			return {withTerms: dataWithTerms, withObjects: dataWithObjects}
		},

		updateAllFilters()
		{
			let allFilters = this.processFilters(this.displayFilters)
			this.allFiltersWithObjects = allFilters.withObjects
			this.allFiltersWithTerms = allFilters.withTerms
		},

		updateVisibleFilters()
		{
			let visiableFilters = this.processFilters(this.visibleFilters)
			this.visiableFiltersWithObjects = visiableFilters.withObjects
			this.visiableFiltersWithTerms = visiableFilters.withTerms
		},

		updateFilters()
		{
			this.updateAllFilters()
			this.updateVisibleFilters()
		},

		updateUrlHash: function(queryParams = null) {
			let _this = this;

			if ( queryParams == null )
			{
				queryParams = this.currentQueryParams;
			}

			// Temporary remove event listener on hashchange when programmatically updating the hash
			window.removeEventListener("hashchange", this.setStateFromHash, false);
			this.searchEnabled = false;

			this.updateAllFilters() // We need to make sure that the processed filters are up to date
			queryParams = Object.assign(queryParams, this.allFiltersWithTerms);

			if ( this.page > 1 )
			{
				queryParams = Object.assign(queryParams, {page: this.page});
			}

			if ( this.sortBy != 'relevance' )
			{
				queryParams = Object.assign(queryParams, {sort: this.sortBy});
			}

			if ( this.listingStyle == 'gallery' )
			{
				delete queryParams['style'];
			}
			else
			{
				queryParams = Object.assign(queryParams, {style: this.listingStyle});
			}

			this.currentQueryParams = queryParams;

			location.hash = queryString.stringify(queryParams);

			// Add event listener again
			this.searchEnabled = true;
			setTimeout(function() {
				window.addEventListener("hashchange", _this.setStateFromHash, false);
			}, 0);

			return queryParams;
		},

		getCurrentUrlHashWithoutPublishers: function()
		{
			let queryParams = JSON.parse(JSON.stringify(this.currentQueryParams));

			delete queryParams.publishers;

			return queryString.stringify(queryParams);
		},

		showAllPublishersLink: function() {
			return this.hiddenFilters.hasOwnProperty('publishers') && this.preselectedFiltersParsed.hasOwnProperty('publishers') && this.currentQueryParams.terms;
		},

		setPage: function(page) {
			this.page = page;
			console.log('set page search')
			this.search();
		},

		setListingStyle(style)
		{
			this.listingStyle = style;
			this.updateUrlHash();
		},

		removeFilter(filter)
		{
			filter.active = false;
			console.log('remove filter search');
			this.search();
		}
	}
};