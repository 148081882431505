<script>
	import ClickOutside from 'vue-click-outside';

	export default {
		name: 'minicart',

		props: ['userData'],

		data: function() {
			return {
				cartItems: [],
				showMinicart: false,
				adding: false,
			}
		},

		mounted: function() {
			this.cartUpdated();
		},

		created: function() {
			this.$bus.$on('cart:updated', this.cartUpdated);
			this.$bus.$on('cart:item-added', this.productAddedOrRemoved);
			this.$bus.$on('cart:item-removed', this.productAddedOrRemoved);
		},

		watch: {
			userData: function() {
				this.cartUpdated();
			}
		},

		computed: {
			productCount: function () {
				if ( this.userData )
				{
					if ( this.userData.cart.hasOwnProperty('size') )
					{
						return this.userData.cart.size;
					}
					return this.userData.cart.items.length;
				}

				return null;
			}
		},

		methods: {
			toggleMinicart: function() {
				this.showMinicart = ! this.showMinicart;
			},

			hideMinicart: function() {
				this.showMinicart = false;
			},

			productAddedOrRemoved: function() {
				this.adding = true;

				setTimeout(() => {
					this.adding = false;
				}, 1000);
			},

			cartUpdated: function() {
				if ( this.userData )
				{
					this.cartItems = this.userData.cart.items;
				}
			},

			removeItem: function(item) {
				let data = {
					isbn: item.isbn,
				};

				this.$http.post(window.pressadminBaseUrl + '/cart/remove/', data, {credentials: true}).then(function(response) {
					if ( response.body.cart )
					{
						this.cartItems = response.body.cart.items;
						this.$bus.$emit('cart:updated', response.body.cart);
						this.$bus.$emit('cart:item-removed', data.isbn);
					}
				}, function(response) {

				});
			},
		},

		directives: {
			ClickOutside,
		}
	}
</script>

<template>
	<div v-show="productCount !== null" v-click-outside="hideMinicart">
		<a href="" class="site-menu__cart" :class="adding ? 'site-menu__cart--added' : ''" @click.prevent="toggleMinicart">
			<span class="item-count">{{ productCount }}</span>
			<div class="site-menu__cart__icon"></div>

 			<span v-if="$root.themeName == 'aterforsaljare'" class="login-text">Varukorg</span>
			<span v-else class="login-text">Beställningar</span>
		</a>

		<div class="minibag" :class="showMinicart ? 'minibag--active' : ''">
			<h2 v-if="$root.themeName == 'aterforsaljare'">Varukorg</h2>
			<h2 v-else>Beställningar</h2>

			<span v-if="!cartItems.length > 0" class="minibag__empty">Här var det tomt :(<br/><strong>Ta en titt bland våra <a href="/bocker/">böcker</a></strong></span>

			<ul v-if="cartItems.length > 0">
				<li class="minibag__row" v-for="item in cartItems">
					<p class="minibag__row__booktitle">
						<span v-if="item.hasOwnProperty('quantity')" class="minibag__row__count">{{ item.quantity }}</span>
						{{ item.title }}
					</p>

					<span class="minibag__row__author">
						<span v-for="(author, authorIndex) in item.authors">{{ author.name }}</span><span
						v-if="authorIndex < item.authors.length-1">, </span>
					</span>
					<a class="minibag__row__remove" href="" @click.prevent="removeItem(item)"></a>
				</li>
			</ul>
			<a v-if="$root.themeName == 'aterforsaljare'" class="button button--green" href="/order/korg/">Till kassan</a>
			<a v-else class="button" href="/order/korg/">Till korg</a>
		</div>
	</div>
</template>