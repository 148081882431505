window.$ = require('jquery');
window.jQuery = window.$;

require('lightgallery.js');
require('lg-thumbnail.js');

$(function(){
	let options = {
		mode: 'lg-fade',
		loop: false,
		subHtmlSelectorRelative: true,
		thumbnail: true,
		addClass: 'is-fixed-size',
		hideBarsDelay: 0,
		toggleThumb: false,
		animateThumb: false,
	};

	let lgEl;

	const initLightGallery = function(el) {
		lightGallery(el, options);

		jQuery(el).on('onAfterOpen', function(event) {
			lgEl = jQuery('.lg-outer');
			const lgInnerEl = lgEl.find('.lg-inner');
			const lgActionsEl = lgEl.find('.lg-actions');

			lgEl.children('.lg').addClass('lg-content').wrap('<div class="lg-dialog"></div>')
			lgActionsEl.appendTo(lgInnerEl);
		})

		jQuery(el).on('onBeforeSlide', function(event) {
			lgEl = jQuery('.lg-outer');

			const lg = window.lgData[el.getAttribute('lg-uid')];
			const lgActionsEl = lgEl.find('.lg-actions');

			if (event.originalEvent.detail.index === 0) {
				lgActionsEl.find('.lg-prev').prop('disabled', true);
				lgActionsEl.find('.lg-next').prop('disabled', false);
			} else if (event.originalEvent.detail.index >= lg.items.length - 1) {
				lgActionsEl.find('.lg-prev').prop('disabled', false);
				lgActionsEl.find('.lg-next').prop('disabled', true);
			} else {
				lgActionsEl.find('.lg-prev').prop('disabled', false);
				lgActionsEl.find('.lg-next').prop('disabled', false);
			}
		})
	}

	initLightGallery(document.getElementById('authorimage'), options);
	initLightGallery(document.getElementById('download-author-images'), options);
	initLightGallery(document.getElementById('book-covers'), options);
	initLightGallery(document.getElementById('book-coversm'), options);
	initLightGallery(document.getElementById('download-author-imagesm'), options);
	initLightGallery(document.getElementById('authorimagem'), options);
	initLightGallery(document.getElementById('inlayimages'), options);
	initLightGallery(document.getElementById('inlayimagesm'), options);

	var lightGalleries = document.querySelectorAll('.js-light-gallery');

	for (var i = 0; i < lightGalleries.length; ++i) {
		initLightGallery(lightGalleries[i], options);
	}
});