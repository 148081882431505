<script>
	import FilterBox from './filter-box.vue'
	import Common from './common';

	export default {
		name: 'search-books',

		components: {
			FilterBox
		},

		mixins: [
			Common
		],

		props: [
			'main-book-search-url',
			'use-book-path',
			'missing-image-url',
			'hide-authors',
			'freetext-min-items',
			'user-token'
		],

		data: function() {
			return {
				sortBy: 'rdate',
				prevFilters: null,
				searched: false
			};
		},

		watch: {
			terms: function(newValue, oldValue) {
				if ( oldValue == '' )
				{
					this.sortByOnSearch = 'relevance';
				}

				if ( newValue == '' )
				{
					this.sortByOnSearch = 'rdate';
				}
			}
		},

		methods: {
			getArrayDiff: function (a, b) {
				let added = a.filter(function (item) {
					return b.indexOf(item) === -1;
				});

				let removed = b.filter(function (item) {
					return a.indexOf(item) === -1;
				});

				return {
					added: added,
					removed: removed
				}
			},
			getFilterDiff: function () {
				let result = {}
				if (this.prevFilters)
				{
					let currentFilters = this.allFiltersWithTerms;
					let prevFilters = this.prevFilters;
					let currentKeys = Object.keys(currentFilters);
					let prevKeys = Object.keys(prevFilters);
					let self = this;

					let keysDiff = this.getArrayDiff(currentKeys, prevKeys);

					keysDiff.added.forEach(function (x) {
						result[x] = {
							added: currentFilters[x],
							removed: []
						}
					});

					keysDiff.removed.forEach(function (x) {

						result[x] = {
							added: [],
							removed: prevFilters[x]
						}
					});

					let existingKeys = currentKeys.filter(function (x) {
						return ! (x in result);
					});

					existingKeys.forEach(function (k) {
						let diff = self.getArrayDiff(currentFilters[k], prevFilters[k]);

						if ( diff.added.length || diff.removed.length )
						{
							result[k] = diff;
						}
					});
				}

				return result;
			},
			search: function(page = null) {

				if ( ! this.isSearchEnabled() )
				{
					return;
				}

				this.$refs.searchinput.blur();

				if (this.sortByOnSearch)
				{
					this.sortBy = this.sortByOnSearch;
					this.sortByOnSearch = null;
				}

				if ( page !== null )
				{
					this.page = page;
				}

				let queryParams = {};

				if (this.terms) {
					queryParams['terms'] = this.terms;
				}

				this.updateUrlHash(queryParams);

				let data = this.getDefaultFilters();

				let extraArgs = this.extraArgs ? this.extraArgs : {};

				Object.assign(data, extraArgs, {
					s: this.terms,
					page: this.page,
					limit: 30,
				});

				if (this.sortBy && this.sortBy != 'relevance')
				{
					data['sort'] = this.sortBy;
				}

				// Add active filters to data
				this.updateAllFilters() // We need to make sure that the processed filters are up to date
				let allFiltersWithTerms = this.allFiltersWithTerms;
				data = Object.assign(data, allFiltersWithTerms);

				let filterDiff = this.getFilterDiff();
				let keepOptionsFor = ['ages'];

				keepOptionsFor = keepOptionsFor.filter(function (k) {
					if ( k in filterDiff )
					{
						return k in allFiltersWithTerms;
					}

					return false;
				});

				this.prevFilters = this.allFiltersWithTerms;

				console.debug('Fetching search results');
				let startTime = new Date();

				this.loading = true;

				let headers = {}

				if ( this.userToken )
				{
					data['token'] = this.userToken
				}

				this.$http.get(this.apiUrl, {params: data, headers: headers}).then(response => {
					let endTime = new Date();

					this.loading = false;

					if ( response.body.success )
					{
						if ( response.body.data )
						{
							this.hits = response.body.data.total ? response.body.data.total : 0;
							this.totalPages = response.body.data.total_pages ? response.body.data.total_pages : 0;
							this.items = response.body.data.items ? response.body.data.items : [];

							this.setFilters(response.body.data.filters, keepOptionsFor);
						}
						else
						{
							this.hits = 0;
							this.totalPages = 0;
							this.items = [];
							this.displayFilters = [];
						}

						if ( typeof dataLayer === 'object' && this.terms != '' )
						{
							dataLayer.push({
								'event': 'search',
								'term': this.terms
							});
						}
					}

					if ( this.itemsLoaded )
					{
						setTimeout(() => {
							this.scrollToTop();
						}, 50);
					}

					this.itemsLoaded = true;

					let timeDiff = endTime - startTime;

					console.debug('Time spent: ' + timeDiff);
				}, response => {
					console.error('Error.');
				});
			},

			getBookUrl: function (item) {
				let usePath = parseInt(this.useBookPath);
				return usePath ? item.bookPath : item.bookUrl;
			},

			getMissingUrl: function () {
				let url = this.missingImageUrl;
				if ( ! url )
				{
					url = "";
				}

				return url;
			},

			getAuthorUrl: function (author) {
				let usePath = parseInt(this.useBookPath);
				if (usePath)
				{
					return author.contactPath;
				}
				return author.contactUrls.length > 0 ? author.contactUrls[0].url : '';
			},

			getBookPublishingDate: function (item) {
				return this.sortBy == 'current' ? item.publishingDate : item.currentMainEditionPublishingDate;
			},

			getCoverImage: function (item) {
				return item.coverImage ? item.coverImage : this.getMissingUrl();
			},
		}
	}
</script>

<template>
	<div class="wrapper" v-cloak id="search-top">
		<div class="filterbox-holder col-3">
			<filter-box v-for="(filter, filterIndex) in orderedFilters" :filter="filter" :filter-index="filterIndex" :freetext-min-items="freetextMinItems"
					v-on:filter-changed="search(1)" class="filterbox"></filter-box>
		</div>
		<div class="col-9 m-col-12">
			<h1 class="page-title">{{ title }}</h1>

			<div class="search col-12">
				<div class="col-6">
					<label class="search__input-label">
						<input class="search__input" type="search" name="term" ref="searchinput" placeholder="Sök bland alla våra böcker" v-model="terms" v-on:keyup.enter="search(1)">
						<button class="search__submit" v-on:click="search(1)"></button>
					</label>
				</div>
				<div class="sort-results col-6">
					<div class="sort-results__filter-button col-12">
						<a class="button button--round button--white js-display-mobile-filters filterbox__button" href="javascript:;">Filtrera</a>
					</div>
					<div class="filterbox-holder filterbox-holder--mobile col-6">
						<filter-box v-for="(filter, filterIndex) in orderedFilters" :filter="filter" :filter-index="filterIndex" v-on:filter-changed="search(1)" class="filterbox"></filter-box>
					</div>
					<div class="sort-results__views">
						<a @click.prevent="setListingStyle('gallery')" class="sort-results__views__view sort-results__views__view--normal"
						   :class="listingStyle == 'gallery' ? 'sort-results__views__view--active' : ''"></a>
						<a @click.prevent="setListingStyle('list')" class="sort-results__views__view sort-results__views__view--listing"
						   :class="listingStyle == 'list' ? 'sort-results__views__view--active' : ''"></a>
					</div>
					<div class="sort-results__sorting">
						<label>
							Sorterat på:
							<select v-model="sortBy" v-on:change="setPage(1)">
								<option v-for="(label, value) in sortOptions" :value="value">{{ label }}</option>
							</select>
						</label>
					</div>
				</div>
			</div>

			<div class="col-12" v-if="visiableFiltersWithObjects">
				<ul class="search-active-filters">
					<template v-for="activeFilterTerms, activeFilter in visiableFiltersWithObjects">
						<li v-for="activeFilterTerm in activeFilterTerms">
							{{ activeFilterTerm.key }}
							<a href="" v-on:click.prevent="removeFilter(activeFilterTerm)"><img src="/wp-content/themes/bonnierforlagen/img/icon-close.svg" alt="Ta bort filter"></a>
						</li>
					</template>
				</ul>
			</div>

			<div class="col-12" v-if="hits !== null">
				<span class="sort-results__hits">{{ hits }} st träffar</span>

				<span class="sort-results__search-all-publishers" v-if="showAllPublishersLink()">
					Vill du söka bland alla <a :href="mainBookSearchUrl + '#' + getCurrentUrlHashWithoutPublishers()">Bonnierförlagens böcker</a>?
				</span>
			</div>

			<div class="search-results-wrapper" :class="loading ? 'search-results-wrapper--loading' : ''">

				<div v-if="listingStyle == 'gallery'" class="book-columns book-columns--six">

					<div v-for="item in items" class="book-columns__item">
						<slot name="gallery-item" :item="item" :search="this" :getBookUrl="getBookUrl" :getMissingUrl="getMissingUrl" :getAuthorUrl="getAuthorUrl">
							<a class="book-columns__book-link" :href="getBookUrl(item)">
								<div v-if="getCoverImage(item)" class="book-columns__cover">
									<img :src="getCoverImage(item)" :alt="item.title">
								</div>
								<div v-else class="book-columns__cover">

								</div>
								<h3 class="book-columns__title">{{ item.title }}</h3>
							</a>

							<div class="book-columns__author-wrap" v-if="! hideAuthors">
								<template v-for="(author, authorIndex) in item.authors">
									<template v-if="author.contactUrls.length == 0">
										<span class="book-columns__book-authors book-columns__book-authors--no-link">{{ author.name }}</span><template
										v-if="authorIndex < item.authors.length-1">, </template>
									</template>

									<template v-else-if="author.contactUrls.length == 1">
										<a :href="getAuthorUrl(author)" class="book-columns__book-authors">{{ author.name }}</a><template
										v-if="authorIndex < item.authors.length-1">, </template>
									</template>

									<template v-else-if="author.contactUrls.length >= 2">
										<template class="book-columns__book-authors">
											{{ author.name }} (<template v-for="(contactUrl, contactUrlIndex) in author.contactUrls"><a
											class="book-columns__book-authors"
											:href="contactUrl.url">{{ contactUrl.publisher_name }}</a><template
											v-if="contactUrlIndex < author.contactUrls.length-1">, </template></template>)
										</template><template v-if="authorIndex < item.authors.length-1">, </template>
									</template>
								</template>
							</div>
						</slot>
					</div>
				</div>


				<div v-else-if="listingStyle == 'list'" class="table-wrapper">
					<div class="table">
						<div class="table__head">
							<div class="table__head__column">
								<p>Titel</p>
							</div>
							<div class="table__head__column" v-if="! hideAuthors">
								<p>Författare</p>
							</div>
							<div class="table__head__column">
								<p>Förlag</p>
							</div>
							<div class="table__head__column">
								<p>Utgivningsdag</p>
							</div>
						</div>
						<div class="table__body">
							<div v-for="item in items" class="table__body__row">
								<div class="table__body__row__column">
									<a :href="getBookUrl(item)">{{ item.title }}</a>
								</div>
								<div class="table__body__row__column" v-if="! hideAuthors">
									<span v-for="(author, authorIndex) in item.authors">
										<span v-if="author.contactUrls.length == 0">
											<span class="search-results__author-name">{{ author.name }}</span>
										</span>

										<span v-else-if="author.contactUrls.length == 1">
											<a :href="getAuthorUrl(author)" class="search-results__author-name">{{ author.name }}</a>
										</span>

										<span v-else-if="author.contactUrls.length >= 2">
											<span class="search-results__author-name">
												{{ author.name }} (<span v-for="(contactUrl, contactUrlIndex) in author.contactUrls"><a
												class="search-results__author_link"
												:href="contactUrl.url">{{ contactUrl.publisher_name }}</a><span
												v-if="contactUrlIndex < author.contactUrls.length-1">, </span></span>)
											</span>
										</span>
									</span>
								</div>
								<div class="table__body__row__column">
									<a :href="item.publisher.url">{{ item.publisher.name }}</a>
								</div>
								<div class="table__body__row__column">
									<p>{{ getBookPublishingDate(item) }}</p>
								</div>
							</div>
						</div>
					</div>
				</div>

			</div>


			<div class="pagination col-12" v-if="totalPages > 0">
				<div class="pagination__wrap">
					<a class="pagination__first" v-bind:class="{ inactive: page <= 1 }" href="" v-on:click.prevent="setPage(1)"></a>
					<a class="pagination__prev" v-bind:class="{ inactive: page <= 1 }" href="" v-on:click.prevent="setPage(page-1)"></a>

					<span class="pagination__count">Sida {{ page }} av {{ totalPages }}</span>

					<a class="pagination__next" v-bind:class="{ inactive: page + 1 > totalPages }" href="" v-on:click.prevent="setPage(page + 1)"></a>
					<a class="pagination__last" v-bind:class="{ inactive: page + 1 > totalPages }" href=""
					   v-on:click.prevent="setPage(totalPages)"></a>
				</div>
			</div>

		</div>
	</div>
</template>