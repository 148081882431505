import $ from 'jquery';

module.exports = function() {
	$(function() {

		//Menu handler
		$('.js-site-menu').on('click', function() {
			$('.site-menu__hamburger').toggleClass('site-menu__hamburger--open');
			$('.site-menu').toggleClass('site-menu--open');
			$(this).toggleClass('hidden');

			if ( $('.site-menu').hasClass('site-menu--open') )
			{
				$('.menu-text').html('Stäng');

			}
			else
			{
				$('.menu-text').html('Meny');
				$('.js-site-menu').removeClass('hidden');
			}
		});

		$('.js-menu-open-sub').on('click', function(event) {
			if ( $(this).parent().hasClass('site-menu__sub-wrap--active-parent') )
			{
				$(this).parent().removeClass('site-menu__sub-wrap--active-parent');
			}
			else
			{
				$(this).next().toggleClass('site-menu__submenu--visible');
				$(this).toggleClass('open');
				$(this).parent().find('.site-menu__links').toggleClass('site-menu__links--open');
			}
		});

		$(window).scroll(function() {
			var scroll = $(window).scrollTop();
			if ( scroll >= 0.001 * $(window).height() )
			{
				$(".menu-bar").addClass('menu-bar--minimized');
			}
			else
			{
				$(".menu-bar").removeClass('menu-bar--minimized');
			}
		});

		if ( $(window).scrollTop() > 0 )
		{
			$(".menu-bar").addClass('menu-bar--minimized menu-bar--bg');
		}
		else
		{
			$(".menu-bar").removeClass('menu-bar--minimized menu-bar--bg');
		}


		$(window).scroll(function() {
			var scroll = $(window).scrollTop();
			if ( scroll >= 100 )
			{
				$(".menu-bar").addClass('menu-bar--bg');
			}
			else
			{
				$(".menu-bar").removeClass('menu-bar--bg');
			}
		});


		$('.top-section__title a').hover(function() {
			$(this).addClass('hover');
			$('.top-section__title').addClass('top-section__title--fade');
			$('.top-section__count').addClass('fade');
		}, function() {
			$(this).removeClass('hover');
			$('.top-section__title').removeClass('top-section__title--fade');
			$('.top-section__count').removeClass('fade');
		});


		//Fb share
		$('.js-share-facebook').on('click', function(e) {
			e.preventDefault();
			return window.open(this.href, "facebook-share", "width=580,height=296");
		});

		$('.js-show-more-press-quotes').click(function(e) {
			e.preventDefault();
			var $this = $(this);
			var $pressQuotes = $this.parents('.js-more-press-quotes').toggleClass('open');

			var isOpen = $pressQuotes.hasClass('open');

			if ( isOpen )
			{
				$this.text('Visa mindre');
			}
			else
			{
				$this.text('Visa mer');
				var offset = $pressQuotes.offset();
				if ( offset )
				{

					$("html, body").animate({scrollTop: offset.top - 140}, 200)
				}
			}
		})
	});
};