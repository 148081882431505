import $ from 'jquery';

module.exports = function() {
	$(function() {
		// Click on Adlibris links in book details
		$('.book__content__details').on('click', 'a', function(event) {
			let url = $(event.currentTarget).attr('href');

			if ( url.indexOf('//www.adlibris.') != -1 )
			{
				if ( typeof ga === 'function' )
				{
					ga.getAll()[0].send('event', {
						eventCategory: 'Outbound Link',
						eventAction: 'Click',
						eventLabel: url
					});
				}
			}
		});

		// Click on Adlibris buy button in header
		$('.book__content__intro').on('click', 'a', function(event) {
			let url = $(event.currentTarget).attr('href');

			if ( url.indexOf('//www.adlibris.') != -1 )
			{
				if ( typeof ga === 'function' )
				{
					ga.getAll()[0].send('event', {
						eventCategory: 'Buy Button',
						eventAction: 'Click',
						eventLabel: url
					});
				}
			}
		});

		$('.article__share').on('click', 'a', function(event) {
			let url = $(event.currentTarget).attr('href');
			let site = null;

			if ( url.indexOf('//www.twitter.com/') != -1 )
			{
				site = 'Twitter';
			}
			else if ( url.indexOf('//www.linkedin.com/') != -1 )
			{
				site = 'LinkedIn';
			}
			else if ( url.indexOf('//www.facebook.com/') != -1 )
			{
				site = 'Facebook';
			}

			if ( typeof ga === 'function' )
			{
				ga.getAll()[0].send('event', {
					eventCategory: 'Share',
					eventAction: 'Click',
					eventLabel: site
				});
			}
		});
	});
};