<template>
	<div>
		<h2>Beställ fakturakopior</h2>

		<div v-if="orderSent" class="success-message">
			Beställning skickad!
		</div>
		<div v-else>
			<div class="invoice-copies--order-form">
				<label>
					<div>Namn beställare</div>
					<input type="text" v-model="customerName">
				</label>

				<label>
					<div>E-post beställare</div>
					<input type="text" v-model="customerEmail">
				</label>

				<label>
					<div>Meddelande (valfritt)</div>
					<textarea v-model="message"></textarea>
				</label>

				<label>
					<div>Valda fakturor</div>
				</label>
				<div class="selected-invoices">
					{{ selectedInvoiceIds.join(', ') }}
					<span v-if="selectedInvoiceIds.length == 0">(Välj faktor nedan)</span>
				</div>

				<button type="submit" class="button" :class="{disabled: ! isValid()}" @click="order">Skicka</button>
			</div>

			<div class="table-container">
				<table>
					<tr>
						<th colspan="2">Faktura-/kreditnummer</th>
						<th>Datum</th>
						<th>Antal ex</th>
						<th>Varuvärde (exkl. moms)</th>
					</tr>

					<tr v-for="invoice in invoices" @click.prevent="selectInvoice(invoice.invoice_number)" class="clickable">
						<td>
							<input type="checkbox" :checked="isSelected(invoice.invoice_number)" @click.stop="selectInvoice(invoice.invoice_number)">
						</td>
						<td>
							{{ invoice.invoice_number }}
						</td>
						<td>{{ invoice.date }}</td>
						<td>{{ invoice.delivered_quantity }}</td>
						<td style="text-align: right">{{ invoice.sum }} kr</td>
					</tr>
				</table>
			</div>
		</div>
	</div>
</template>

<script>
import Vue from 'vue'
import VueResource from 'vue-resource'

Vue.use(VueResource)

export default {
	name: 'invoice-copy',

	props: ['userData'],

	data: function () {
		return {
			customerName: '',
			customerEmail: '',
			message: '',
			invoices: [],
			selectedInvoiceIds: [],
			errorResponse: null,
			errors: [],
			orderSent: false,
			sending: false,
		}
	},

	mounted: function () {
		this.loadInvoices()

		this.customerEmail = this.userData.contact_email
		this.customerName = this.userData.contact_person
	},

	methods: {
		selectInvoice: function (id) {
			if (this.selectedInvoiceIds.includes(id)) {
				this.selectedInvoiceIds = this.selectedInvoiceIds.filter(item => item !== id)
			} else {
				this.selectedInvoiceIds.push(id)
			}
		},

		isSelected: function(id) {
			return this.selectedInvoiceIds.includes(id)
		},

		loadInvoices: function () {
			var data = {
				credentials: true,
				withCredentials: true,
			}

			this.$http.get(window.pressadminBaseUrl + '/accounts-receivable/invoice-copy', data).then(function (response) {
				this.response = response.body
				this.invoices = response.body.invoices
				this.errors = false
			}, function (response) {
				this.errorResponse = response.body
				this.errors = response.body.error
			})
		},

		isValid: function () {
			if (this.customerName.length == 0)
			{
				return false
			}

			if (this.sending)
			{
				return false
			}

			if (!String(this.customerEmail).toLowerCase().match(
				/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
			))
			{
				return false
			}

			if (this.selectedInvoiceIds.length == 0)
			{
				return false
			}

			return true
		},

		order: function () {
			if (! this.isValid())
			{
				return
			}

			let data = {
				customer_name: this.customerName,
				customer_email: this.customerEmail,
				message: this.message,
				invoice_ids: this.selectedInvoiceIds,
			}

			let options = {
				credentials: true,
			}

			this.sending = true

			this.$http.post(window.pressadminBaseUrl + '/accounts-receivable/invoice-copy', data, options).then(function (response) {
				if (response.status == 200)
				{
					this.orderSent = true
					this.sending = false
				}
			}, function (response) {

			})

			if (typeof ga === 'function')
			{
				ga.getAll()[0].send('event', {
					eventCategory: 'ResellerUsers',
					eventAction: 'Order',
					eventLabel: 'InvoiceCopy',
				})
			}
		},
	},
}
</script>

<style scoped>
h2 {
	margin-top: 10px;
	margin-bottom: 25px;
}

.table-container {
	margin-top: 50px;
	margin-bottom: 50px;
	background-color: #F4F4F4;
	padding: 40px;
}

table {
	width: 100%;
}

th, td {
	padding-right: 25px;
	padding-bottom: 10px;
	text-align: left;
}

td:first-child {
	padding-right: 10px;
}

label {
	font-weight: bold;
	font-size: 18px;
	line-height: 24px;
	margin-bottom: 10px;

	display: block;
	margin-top: 15px;
}

input[type="text"], textarea {
	width: 250px;
	display: block;
	padding: 14px 20px;
	font-size: 18px;
	line-height: 24px;
	margin-bottom: 30px;
	outline: 0;
}

textarea {
	height: 150px;
}

button {
	margin-top: 25px;
}

button.disabled:hover {
	text-decoration: none;
}

button.disabled {
	opacity: 0.5;
	cursor: inherit;
}

.success-message {
	margin-bottom: 40px;
}

.clickable {
	cursor: pointer;
}

</style>