<script>
export default {
	name: 'returns',

	props: ['userData'],

	data: function() {
		return {

		}
	},

	mounted: function() {

	},

	created: function() {
	},

	watch: {
		userData: function() {

		}
	},

	computed: {
		showLink: function() {
			if ( this.$root.themeName != 'aterforsaljare' )
			{
				return false;
			}


			if ( this.userData && this.userData.returns_enabled === true )
			{
				return true;
			}

			return false;
		},

		returnsUrl: function() {
			if ( this.userData && this.userData.returns_enabled === true ) {
				return this.userData.returns_url;
			}

			return ''
		}
	},

	methods: {}
}
</script>

<template>
	<div v-show="showLink">
		<a :href="returnsUrl" target="_blank" class="site-menu__returns">
			<div class="site-menu__returns__icon"></div>
			<span v-if="$root.themeName == 'aterforsaljare'" class="login-text">Returer</span>
		</a>
	</div>
</template>